import { PrismaClient } from '@prisma/client';
import LoginForm from '../Client/Components/Auth/LoginForm';
import AuthSidebar from '../Client/Components/Auth/AuthSidebar';
import Demo from '../Client/Components/Demo';
import Layout from '../Client/Components/Layout';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import SEO from '../Client/Components/SEO';
import Scripts from '../Client/Components/Scripts';

function Login(props) {
  // const { email } = props.user;
  // const auth = useSelector((state) => state.auth);
  // const router = useRouter();
  // useEffect(() => {
  //   // console.log('auth state', auth);
  //   const redirectUrl = router.query.redirect;
  //   console.log('router', redirectUrl);
  //   if (auth.isLogin === true) {
  //     // router.push(redirectUrl);
  //     router.push('/dashboardssss');
  //   }
  // }, []);

  return (
    <div>
      <SEO title="Login" desc="Login to your 1LinksApp account. 1LinsApp is a free and elegant link-in-bio tool for creators. Claim your name in less than a minute!" />
      <Scripts chatWidget={true} beamer={false} />
      <LoginForm />
    </div>
  );
}

export async function getServerSideProps(context) {
  console.log('server props', context);
  //   const cookies = context.req.headers.cookie;
  //   console.log(context.req.headers);
  // const auth = useSelector((state) => state.auth);
  // const prisma = new PrismaClient();
  // const result = await prisma.user.findUnique({
  //   where: {
  //     ID: 1,
  //   },
  //   select: { email: true, password: true },
  // });
  // console.log(result);
  return {
    props: { user: 'result' }, // will be passed to the page component as props
  };
}

Login.getLayout = function getLayout(page) {
  return <AuthSidebar>{page}</AuthSidebar>;
};

export default Login;
